import React from "react";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as CONST from "./const";

function Need() {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = withStyles({
    root: {
      margin: 5,
      //    border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 5,
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      //    backgroundColor: 'rgba(0, 0, 0, .03)',
      backgroundColor: '#ffffff',
      borderBottom: '2px solid white',

      // minHeight: 56,
      // '&$expanded': {
      //   minHeight: 56,
      // },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  // const AccordionDetails = withStyles((theme) => ({
  //   root: {
  //     paddingRight: theme.spacing(5),
  //   },
  // }))(MuiAccordionDetails);

  const qaList = [
    {
      id: 'panel1d-header', panel: 'panel1', q: '自分で考えて動く力',
      a: '<p>始めは誰でも先輩に仕事を教わりながら成長していきます。当社の社員には後輩を育成したいという気持ちの強い人が多いため、入社後は色々なことを教わるでしょう。</p>'
        + '<p>しかし、若手社員達には決して受け身の姿勢でいて欲しくありません。「今自分がやるべきことは何か」「(雑務等も含めて)自分に出来る仕事を見つけるんだ」と考え、実行に移す積極性を持った人と働きたいと考えています。そんな向上心のある若手社員は、配属後の早い段階で「配属してくれて良かった」と思われるチームの戦力になれるはずです！</p>'
    },
    {
      id: 'panel2d-header', panel: 'panel2', q: 'コミュニケーション能力',
      a: '<p>社会で求められるコミュニケーション力とは何でしょうか？学生のうちは「人と楽しく会話し、仲間を増やす力」であったかもしれません。社会でもそのスキルは大切かもしれませんが、それよりも遥かに重要なことは、「相手の話を理解する力、引き出す力、気持ちを察する力」と、「自分の考えを分かりやすく伝える力」です。システムエンジニアの仕事は、パソコンに向かい一人で作業をし続けるものではなく、開発チームのメンバーと積極的に会話し、正確な情報の受け渡しをしていくことを繰り返し、一つのシステムを作り上げていく仕事であるためです。</p>'
    },
    {
      id: 'panel3d-header', panel: 'panel3', q: 'ポジティブな姿勢',
      a: '<p>仕事は楽しいと思いますが、大変なことも多々あります。失敗をすることもあるでしょう。そんな時、クヨクヨせずに前向きに対処していける方とぜひ働きたいと思います。共に苦労を乗り越えられると思えるからです。また、前向きな人の周りには自然と人（お客様、先輩、後輩…）が集まってくると思いませんか？</p>'
    },
    {
      id: 'panel4d-header', panel: 'panel4', q: '自己学習の継続力',
      a: '<p>新入社員研修で多くのことを勉強するのは勿論のこと、その後は学んだ技術や知識を更に磨いていく必要があります。ＩＴ技術は変化（進化）をしていきますから、それに追いつく、または先取りして行くためにいつも情報にアンテナを張り、学び続けることが求められているのです。</p>'
        + '<p>また、身に付けるのは技術だけではありません。質の高いシステムを作るためには、それを使っていただくお客様の業界知識や業務内容も学ばなくてはなりません。</p>'
        + '<p>…こう聞くと厳しい様に思えますが、自分の努力次第で「自分は進化しているんだ！」という実感が持てるおもしろさがこの仕事にはあると思います！</p>'
    },
  ];

  return (
    <React.Fragment>
      <div className={CONST.PC_SHOW_CLASS + " z-20"}>
        <div className="grid grid-cols-2 3xl:grid-cols-4 gap-5">
          {
            qaList.map((qa) => (
              <div key={qa.id} className={CONST.BG_WHITE_BLOCK}>
                <p className="text-lg pb-5 xl:pb-8">{qa.q}</p>
                <div className="tracking-tighter leading-7 font-light" dangerouslySetInnerHTML={{ __html: qa.a }}></div>
              </div>
            ))
          }

        </div>
      </div>
      <div className={CONST.PC_HIDDEN_CLASS + " z-20"}>
        {
          qaList.map((qa) => (
            <Accordion key={qa.id} square expanded={expanded === qa.panel} onChange={handleChange(qa.panel)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon className="text-5xl" />} aria-controls={qa.panel} id={qa.id}>
                <p className="px-5 w-full">{qa.q}</p>
              </AccordionSummary>
              <MuiAccordionDetails>
                <div className="px-5 w-full" dangerouslySetInnerHTML={{ __html: qa.a }}></div>
              </MuiAccordionDetails>
            </Accordion>
          ))
        }
      </div >
    </React.Fragment>
  );
}

export default Need;